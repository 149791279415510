<!-- 包号扫描 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('包号扫描')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" >
    <el-col :xs="24" :sm="12">
        <el-input :placeholder="msg('扫描包号')" ref="inputer1" @keyup.native="autoSearch" v-model.trim="innerCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
  </el-row>
  <el-row :gutter="10"  class="query-wrap" >
    <template>
    <el-table
      :data="tableData"
      border
      :max-height="docHeight"
      style="width: 50%">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="code" label="包号" width="250"></el-table-column>
      <el-table-column prop="type" label="类型" width="200"></el-table-column>
    </el-table>
  </template>
  </el-row>

  <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>
    
</div> 
</template> 

<script>
// @ is an alias to /src
import HM from "../../assets/media/HM.mp3";
import XZ from "../../assets/media/XZ.mp3";
import XP from "../../assets/media/XP.mp3";
import XPDD from "../../assets/media/XPDD.mp3";
import XPGS from "../../assets/media/XPGS.mp3";
import HC from "../../assets/media/HC.mp3";
import CD from "../../assets/media/CD.mp3";
import no from "../../assets/media/no.mp3";
import beihuo from "../../assets/media/beihuo.mp3";
import ERRTYPE from "../../assets/media/ERRTYPE.mp3";
export default {
  name: 'ExpPrintPage',
  components: {
  },
  mounted:function(){
  },
  data(){
    return{
      innerCode: null,
      tableData:[],
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 238;
        }
    }
  },
  methods: {
    autoSearch(e){

        if(e.keyCode == 13){
            this.upper();
        }

    },

    upper(){

      if(!this.innerCode){
        this.$message(this.msg('请输入包号'));
        return;
      }
      
      this.axios.get(this.$kit.api.codeCheckSearch+'?code='+this.innerCode)
      .then((result) => {
        if(result.status){
          var data = result.data;
          let type = data.type;
          this.tableData.push({
            code:data.code,
            type:data.type,
          })
          this.innerCode = null;
          this.$refs['inputer1'].focus();
          if(type == '黑貓'){
            this.playAudio(HM);
          }else if(type == '新竹'){
            this.playAudio(XZ);
          }else if(type == '蝦皮打單'){
            this.playAudio(XPDD);
          }else if(type == '蝦皮過刷'){
            this.playAudio(XPGS);
          }else if(type == '蝦皮'){
            this.playAudio(XP);
          }else if(type == '回倉'){
            this.playAudio(HC);
          }else if(type == '拆袋'){
            this.playAudio(CD);
          }else if(type == '備貨'){
            this.playAudio(beihuo);
          }else{
            this.playAudio(ERRTYPE);
          }
        }else{
          this.innerCode = null;
          this.$refs['inputer1'].focus();
          this.playAudio(no);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },
    playAudio(url){
        let audio = this.$refs.audioRef;
        audio.src = url;
        audio.play();
    },
  }
   
  
}
</script>

<style>
.el-col-lg-6 {
    width: 22%;
}
</style>
