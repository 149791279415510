<template>
<el-form ref="form" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('类型')">
                <el-select v-model="type" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
    
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'OrderEdit',
  data(){
    return{
        type:null,
        editIds:null,
        options: [{
          value: '黑貓',
          label: '黑貓'
        }, {
          value: '新竹',
          label: '新竹'
        }, {
          value: '蝦皮',
          label: '蝦皮'
        }, 
        {
          value: '蝦皮打單',
          label: '蝦皮打單'
        }, 
        {
          value: '蝦皮過刷',
          label: '蝦皮過刷'
        }, 
        {
          value: '回倉',
          label: '回倉'
        },
        {
          value: '拆袋',
          label: '拆袋'
        },
        {
          value: '備貨',
          label: '備貨'
        }
        ],
    }
  }, 
  computed:{
  },
  props:{
  },
  mounted: function(){
    var _this = this;
  },
  methods:{
    initForm(ids){
        this.type = null;
        this.editIds = ids;
    },
    onSubmit(){
        var _this = this;
        if(!this.type){
            this.$message(this.msg('请选择要修改的类型'));
            return;
        }
        this.$axios.post(this.$kit.api.codeCheckEdit+"?type="+this.type+"&ids="+this.editIds)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || _this.msg('更新成功'), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
               
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
