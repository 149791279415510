<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('台湾库存匹配记录')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'120px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('单号')" v-model="query.code" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('操作时间')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input type="textarea" :rows="4" :placeholder="msg('批量查询')" v-model="query.codeStrs" size="small">
        </el-input>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar15" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="success" size="mini" @click="cancel()">
          <i class="el-icon-upload2" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('撤销匹配')}}</span>
          </transition>
        </el-button>
        <!-- <el-button type="success" size="mini" @click="updateType()">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('修改类型')}}</span>
          </transition>
        </el-button> -->
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getSimpleProductNames(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('台湾库存匹配列表'),sheetName: 'Sheet1' })">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="downloadList">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出全部')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
  </vxe-toolbar>
  
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="simpleProductNames" 
      show-overflow 
      :span-method="mergeRowMethod"
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')"  min-width="60" :fixed="isMinScreen ? null : 'left'" 
          sortable 
      ></vxe-table-column>
      <vxe-table-column field="orderCode" :title="msg('虾皮单号')" min-width="120" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="orderName" :title="msg('标题')" min-width="150" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="orderSpec" :title="msg('规格')" min-width="100" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="orderSku" :title="msg('SKU')" min-width="100" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="orderQty" :title="msg('默认数量')" min-width="80" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="resendQty" :title="msg('手动选择数量')" min-width="80" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="code" :title="msg('库存单号')" min-width="120" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="productTitle" :title="msg('库存貨物代號')" min-width="120" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="productName" :title="msg('库存品名')" min-width="120" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="productSpec" :title="msg('库存规格')" min-width="80" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="shelfCode" :title="msg('库存架位号')" min-width="80" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="fullname" :title="msg('操作人')" min-width="80" 
      ></vxe-table-column> 
      <vxe-table-column field="created" :title="msg('操作时间')" min-width="120" 
      ></vxe-table-column>     
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

</div>
</template> 

<script>
// @ is an alias to /src
import XEUtils from 'xe-utils';
export default {
  name: 'SimpleProductName',
  components: {
  },
  mounted:function(){
    this.getSimpleProductNames();
    var _this = this;
    this.columns = this.$kit.getColumns('exp-spn-filter');
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          code: null,
          codeStrs:null,
          Date: [], 
        },
        currentPage: 1,   //分页的当前页
        simpleProductNames: [],   //表格数据列表
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        curr:null,
        codeImportVisible:false,
        editVisible:false,
        editIds:null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    hendleOpenedQuery(){
      this.$nextTick(() => {
        this.$refs.queryForm.init();
      });
      
    },
    uploadCode(){
      this.codeImportVisible = true;
    },
    codeImport(){
        this.$nextTick(() => {
        this.$refs.shelfCodeImport.initForm();
      });
    },
    getSimpleProductNames: function(start){ //获取列表
      var _this = this;

      if(this.query.codeStrs){
          let cs = this.query.codeStrs.split(/\s+/);
  
          let css = [];
          for(let i=0; i<cs.length; i++){
              if(cs[i]) css.push(cs[i]);
          }
          this.query["codes"] = css.join(",");
      }else{
          this.query["codes"] = '';
      }

      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        param.start = 0;
        this.currentPage = 1;
      }

      this.axios.post(this.$kit.api.expStoreMatchList, param).then(function (result) {
        if(result.status){
          if(_this.simpleProductNames.length>0)
            _this.simpleProductNames.splice(0, _this.simpleProductNames.length);
          
          _this.total = result.data.recordsTotal;
          var orderDatas = result.data.data;

          for(var i=0; i<orderDatas.length; i++){
            var item = orderDatas[i];
            if(item.weight > 0){
              item.weight = (item.weight*0.001).toFixed(2);
            }
            _this.simpleProductNames.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    downloadList(){
      var _this = this;
      if(this.query.codeStrs){
          let cs = this.query.codeStrs.split(/\s+/);
  
          let css = [];
          for(let i=0; i<cs.length; i++){
              if(cs[i]) css.push(cs[i]);
          }
          this.query["codes"] = css.join(",");
      }else{
          this.query["codes"] = '';
      }
      var param = this.$kit.rebuildQuery(this.query);
      window.location.href= this.$kit.api.downloadExpStoreMatch+"?data="+encodeURIComponent(JSON.stringify(param));
    },
    mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
      const fields = ['orderCode']
      const cellValue = XEUtils.get(row, column.property)
      if (cellValue && fields.includes(column.property) && visibleData) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getSimpleProductNames();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getSimpleProductNames();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getSimpleProductNames();
    },
    updateType(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var ids = selectes.map( o => o.id);
      this.editIds = ids;
      this.editVisible = true;
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getSimpleProductNames();
      }
      this.editVisible = false;
    },
    //撤销匹配结果
    cancel(){
        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
          this.$message(this.msg('请选中一条数据进行操作'));
          return;
        }
        if(selectes.length>1){
          this.$message(this.msg('请选中一条数据进行操作'));
          return;
        }
        var _this = this;
        var ids = selectes.map( o => o.id);
        this.$confirm(this.msg('确定要撤销该订单吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.matchCancel, ids)
            .then(function(result){
              if(result.status){
                _this.$message(result.msg || _this.msg('撤销成功'));
                _this.getSimpleProductNames();
              }else{
                _this.$message(result.msg || _this.msg('撤销失败'));
              }
          });
        });
    },
  }
}
</script>

<style class="scss" scoped>


</style>
